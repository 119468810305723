<template>
  <b-row class="wrap-border">
    <b-col>
      <b-card>
        <b-card-body>
          <b-table
              :fields="fields"
              :items="items">
            <template #cell(name)="data">
              <a :href="`/stock/`+data.item.code">{{ data.item.name }}</a>
            </template>
          </b-table>
        </b-card-body>

      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BRow,
  BTable,
} from "bootstrap-vue";
import StockIndexCard from "@/views/Home/StockIndexCard";
import HotDiscussionCard from "@/views/Home/HotDiscussionCard";
import AppTimeline from "@core/components/app-timeline/AppTimeline";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem";

export default {
  components: {
    BCard,
    BCardBody,
    BCol,
    BRow,
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: '股票名',
        },
        {
          key: 'type',
          label: '股票类型',
        },
        {
          key: 'market',
          label: '交易市场',
        },
        {
          key: 'region',
          label: '发行地区',
        },
        {
          key: 'currency',
          label: '货币类型',
        },
      ],
      items: [],
    }
  },
  created() {
    this.$http.get("/api/system/stock/userSelect/list", {
      'type': this.stock_type_code,
      'key': this.search_key,
    }).then(res=>{
      if (res.data.code===200){
        this.items=res.data.data
      }
    })
  },
}
</script>

<style scoped>

</style>