<template>
  <b-card
      no-body
      v-on:click="jump"
      style="cursor:pointer">
    <b-card-header class="pb-1">
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar
              size="42"
              :src="user_avatar"
          />
        </b-media-aside>
        <b-media-body>
          <h5 class="mb-0">
            {{ user_name }}
          </h5>
          <small class="text-muted">{{ release_time }}</small>
        </b-media-body>
      </b-media>
    </b-card-header>
    <b-card-body>
      <h4 class="apply-job-title">
        {{ title }}
      </h4>
      <b-card-text class="mb-2" v-html="content">
        {{ content }}
      </b-card-text>
    </b-card-body>
    <hr>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, BCardText, BCardBody, BButton
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
  },
  props: {
    user_id: {
      type: [Number, String],
      required: true,
    },
    discussion_id: {
      type: [Number, String],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    user_name: {
      type: String,
      required: true,
    },
    user_avatar: {
      type: String,
      required: true,
    },
    release_time: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  methods: {
    jump() {
      window.location.href = this.link
    }
  }


}
</script>

<style scoped>

</style>